
import { defineComponent, ref, computed, reactive, onMounted, watchEffect, getCurrentInstance, onBeforeMount } from 'vue'
import { store } from "@/store";
import { FormInstance } from 'element-plus';
import Customers from '@/core/services/Callable/Customers';
import Etcs from '@/core/services/Callable/Etcs';
import Validations from '@/core/services/etc/Validations';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import router from '@/router/clean';
import { etc, rule, notification, constant } from '@/store/stateless';
import Invoice from '@/core/services/Callable/Invoice';
import { InvoiceMutationTypes } from '@/store/enums/Invoice/Mutations';
// import { invoiceDefault } from "@/core/utils/invoice.js";
import { ElLoading } from 'element-plus'

export default defineComponent({
    setup() {
        const ruleFormRef = ref<FormInstance>()

        const payment_warning = ref()
        const invoiceDefault = ref({
            quote_ref_no:null,
            customer_id: null,
            lead_id: null,
            insurance_plan: null,
            copay: null,
            orginal_policy_price: 0,
            policy_price: 0,
            invoice_amount:0,
            sales_price: 0,
            basmah:0,
            basmah_vat:0,
            is_discount_given: false,
            discount_amount: 0,
            is_split_payment: false,
            payment_type: null,
            pending_payment_type: null,
            installments: null,
            order_description: null,
            reference_no: null,
            payment_date: null,
            file_receipt: null,
            language: "en",
            expiry_date_time: null,
            client_classification:null,
            members: null,
            categories:[],
            icp: 0,
            icp_vat: 0,
            non_taxable_fee:0
        })

        const invoiceModalRef = ref();
        const hasPendingInvoice = ref()
        const pending_invoice = ref(false)
        const disabledButton = ref(false)
        const hasDuplicateInvoice = ref(false)

        const health = computed(() => {
            return store.state.customer.health;
        })

        const loading = computed(() => {
            return store.state.customer.detailLoading
        })

        const clickLoading = computed(() => {
            return store.state.invoice.loading
        })

        const invoice = computed(() => {
            return store.state.invoice.groupInvoice;
        })

        const openModal = computed(() => {
            return store.state.invoice.openInvoiceModal;
        });

        const isError = ref<boolean>(false);

        const closeModal = (e) => {
            store.commit(InvoiceMutationTypes.SET_INVOICE_MODAL,false);
        };


        const rules = reactive({
            // insurance_type: [{ validator: Validations.insurance_type, trigger: ['blur'] }],
            insurance_plan: [{ validator: Validations.insurance_plan, trigger: ['blur', 'change','input'] }],
            policy_price: [{ validator: Validations.policy_price, trigger: ['blur', 'change','input'] }],
            invoice_amount: [{ validator: Validations.invoice_amount, trigger: ['blur', 'change','input'] }],
            icp: [{ validator: Validations.icp, trigger: ['blur', 'change'] }],
            non_taxable_fee: [{ validator: Validations.non_taxable_fee, trigger: ['blur', 'change'] }],
            
            // sales_price: [{ validator: Validations.sales_price, trigger: ['blur', 'change','input'] }],
            client_classification: [{ validator: Validations.client_classification, trigger: ['blur', 'change','input'] }],
            discount_amount: [{ validator: Validations.discount_amount, trigger: ['blur', 'change','input'] }],
            payment_type: [{ validator: Validations.payment_type, trigger: ['blur', 'change','input'] }],
            pending_payment_type: [{ validator: Validations.payment_type, trigger: ['blur', 'change','input'] }],
            // order_description: [{ validator: Validations.order_description, trigger: ['blur', 'change','input'] }],
            reference_no: [{ validator: Validations.reference_no, trigger: ['blur', 'change','input'] }],
            payment_date: [{ validator: Validations.payment_date, trigger: ['blur', 'change','input'] }],
            installments: [{ validator: Validations.installments, trigger: ['blur', 'change','input'] }],
            file_receipt: [{ validator: Validations.file_receipt, trigger: ['blur', 'change','input'] }],
            expiry_date_time: [{ validator: Validations.expiry_date_time, trigger: ['blur', 'change','input'] }],
        })

        let Receipt: any;
        let chequeReceipt: any;

        const handleFileUpload = (file) => {
            // formData = new FormData();
            // rules.file_data.show = false;
            // file = event.target.files[0];
            // if (file == "" && addInvoiceBodyParam.value.payment_type != "1") {
            //     rules.value.file_data.show = false;
            //     isFormValid.value = false;
            // }
            //

            Receipt = file;
            // console.log('file',file);
        };

        const handleChequeFileUpload = (file) => {
            chequeReceipt = file;
        }

        const revalidate = () => {
            console.log('sdds')
            if(invoice.value.payment_type == '3'){
                ruleFormRef.value?.clearValidate('order_description')
            }
            if(invoice.value.file_receipt){
                ruleFormRef.value?.clearValidate('file_receipt')
            }
            
            if (isSubmitted.value) {
                ruleFormRef.value?.validate((valid) => {
                    if (!valid) {
                        // 
                    }
                })
            }
        }

        watchEffect(() => {
            if(health.value && health.value.has_pending_group_invoice) {
                pending_merchant_ref.value = health.value.has_pending_group_invoice.merchant_reference
                pending_invoice.value = true
                setTimeout(() => {
                    hasPendingInvoice.value.open(true)
                }, 200);
            }
        })

        const isSubmitted = ref(false)
        const top = ref()
        const middle = ref()
        const last = ref()
        const invoiceRef = ref()
        const groupInfoRef = ref()
        const pending_merchant_ref = ref('')
        const makeInvoice = async (formEl: FormInstance | undefined) => {

            Validations.is_reference_no_exist = false
            isSubmitted.value = true
            if (!formEl) return
            formEl.validate(async (valid, fields: any) => {
                // console.log(fields)
                if (valid) {
                    // const payload = invoice.value;
                    hasDuplicateInvoice.value = false
                    payment_warning.value.open(true)
                    const formData = new FormData();
                    // formData.append('invoice', invoice.value);

                    

                    for(const key in invoice.value) {
                        formData.set(key,invoice.value[key]?.toString() ?? '');
                    }
                   
                    const lead_id = router.currentRoute.value.params.lead_id.toString();
                    formData.set('customer_id', health.value.id ? health.value.id.toString() : '')
                    formData.set('policy_sales_agent_id', health.value.agent?.toString() ?? '0')
                    formData.set('lead_id', lead_id)
                    formData.set('invoice_doc', Receipt ?? '');
                    formData.set('cheque_doc', chequeReceipt ?? '');
                    formData.set('email', health.value.email ?? '');
                    formData.set('is_split_payment', invoice.value.is_split_payment ? '1' : '0')
                    formData.set('is_discount_given', invoice.value.is_discount_given ? '1' : '0')
                    formData.set('categories', JSON.stringify(invoice.value.categories))

                    disabledButton.value = true
                    const result = await Invoice.makeGroupInvoice(formData);
                    isSubmitted.value = false
                    payment_warning.value.open(false)
                    // console.log(result); 
                    if (result.status == 200) {


                        notification.success(
                            'Invoice',
                            'Invoice made successfully'
                        );

                    // await Invoice.makeInvoiceEvent({
                    //     driver_id: currentLead.value,
                    //     type: 'health'
                    // })
                        // goLists(health.value.id);

                        // window.open(result.data.data.file_path, '_blank');
                    } else {
                        if(result?.data?.data?.reference_no){
                            Validations.is_reference_no_exist = true
                            rule.customerDetails = 'reference_no'
                            setTimeout(() => {
                                formEl.validateField('reference_no')
                                invoiceRef.value.focus()
                            }, 200);
                        }else if(result?.data?.data?.has_pending_invoice){
                            
                            pending_merchant_ref.value = result?.data?.data?.has_pending_invoice.merchant_reference
                            pending_invoice.value = true
                            setTimeout(() => {
                                hasPendingInvoice.value.open(true)
                            }, 200);
                        }
                        disabledButton.value = false

                    }
                }
                else {
                    const categoriesErrors = Object.keys(fields).filter(x => x.includes('categories'));
                    if(categoriesErrors.length > 0){
                        rule.customerDetails = categoriesErrors[0]
                        if(groupInfoRef.value) groupInfoRef.value.focus();
                    }
                    else{
                        rule.customerDetails = Object.keys(fields)[0]
                        // console.log('rules',rule.customerDetails)
                        if(invoiceRef.value) invoiceRef.value.focus();
                    }
                        disabledButton.value = false

                }

                isSubmitted.value = false

            })
        }



        const inst = getCurrentInstance()


        onMounted(async () => {
            setCurrentPageBreadcrumbs("Make Group Invoice ", [{ label: "Leads & Tasks", value: "CustomerLists" }]);

            const elLoadingS = ElLoading.service({
                text: 'Loading',
                background: 'rgba(0, 0, 0, 0.1)',
            })
            

            // passed city dubai , calcaulate amount means with tax
            await Etcs.getVat();
            await Etcs.getBasmah({insured_city:constant.dubai_emirate , calculate_amount : true});
            await Invoice.setGroupInvoice(invoiceDefault.value)
            // console.log('invoice',invoice.value)
            await Customers.getLeadsByLeadId({
                id: parseInt(router.currentRoute.value.params.lead_id.toString())
            }).then(() => {
                // window.scrollTo(0, 750);
                const listRef:any = inst?.refs[`invoice_information`]
                if(listRef){
                    listRef.scrollIntoView({ block: 'center',behavior: "smooth" });
                }
            })

            // setTimeout(() => {
                elLoadingS.close()
            // },500)

            invoice.value.categories = store.getters.getGroupInvoiceCategories

            // console.log(health.value);
            rule.customerDetails = 'categories.0.amount'
            if(groupInfoRef.value) groupInfoRef.value.focus();
            

        })

        const goLists = (customer_id: any) => {
            router.replace({ name: 'CustomerDetails', params: { customer_id } })
        }



        const checkform = () => {
            const myForm: any = document.querySelectorAll('#last')
            console.log(myForm)
        }

        const currentLead = computed(() => {
            return router.currentRoute.value.params.lead_id
        })

        const redirectIt = () => {
            router.push({
                name: "CustomerDetails",
                params: { customer_id: health.value.id },
            });
        }

        onBeforeMount(() => {
            window.addEventListener("beforeunload", preventNav)
        })

        function preventNav(event) {
            if (!isSubmitted.value) return
            event.preventDefault()
            event.returnValue = ""
        }

        return {
            payment_warning,
            currentLead,
            redirectIt,
            hasPendingInvoice,
            invoiceModalRef,
            store,
            clickLoading,
            checkform,
            top,
            middle,
            last,
            revalidate,
            loading,
            rule,
            rules,
            health,
            ruleFormRef,
            etc,
            goLists,
            makeInvoice,
            handleFileUpload,
            handleChequeFileUpload,
            invoice,
            openModal,
            isError,
            closeModal,
            invoiceRef,
            groupInfoRef,
            disabledButton,
            pending_merchant_ref,
            pending_invoice
        }
    },
})
